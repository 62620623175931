
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { IForms } from '@/store/resources/form/state'
  import { mapGetters } from 'vuex'
  import { Resource } from '@/entities/public'

@Component({
  components: {
    FormDialog: () => import(/* webpackChunkName: "person" */'@/components/core/form/FormDialog.vue'),
    DialogContent: () => import(/* webpackChunkName: "person" */'@/components/core/baseDialog/DialogContent.vue'),
    DynamicForm: () => import('@/components/forms/DynamicForm.vue'),
  },
  computed: {
    ...mapGetters('resources', ['getForm']),
  },
})
  export default class ModelDialog extends Vue {
  @Prop({ type: Number }) id: number
  @Prop({ type: String, required: true }) model!: string
  getForm!: (model: string, slug?: string) => Resource
  _resource!: Resource;
  forms!: IForms
  value = {}

  get resource () {
    const { _resource } = this
    if (_resource) return _resource

    const { model } = this
    return this._resource = this.getForm(model)
  }

  get title () {
    const { resource: { name } } = this
    return name
  }

  get subtitle () {
    const { resource: { description } } = this
    return description
  }

  get divisor () {
    const { subtitle } = this
    return !!(subtitle && subtitle.length)
  }
  }
